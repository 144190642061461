import React, { useEffect, useState } from "react";
import { FiCheck } from "react-icons/fi";
import Button from "components/button";
import LoginStyleWrapper from "./Login.style";
import {
  getAuth,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { auth, signInWithGooglePopup } from "../../../firebase-config";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../../authContext";

const Login = () => {
  const [isChecked, setChecked] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [showForgotPasswordForm, setShowForgotPasswordForm] = useState(false);
  const [recoveryEmail, setRecoveryEmail] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [userCredential, setUserCredential] = useState("");

  const { currentUser } = useAuth();

  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      if (userCredential && userCredential.user) {
        const user = userCredential.user;
        const token = await user.getIdToken();
        setLoggedIn(true);
        navigate("/home");
      } else {
        throw new Error("User data not available");
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const handleGoogleLogin = async () => {
    let idToken = null;
    let requestBody = null;
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      idToken = await result.user.getIdToken();
      if (idToken) {
        localStorage.setItem("jwt", idToken);
      }

      const displayNameArray = result.user.displayName
        ? result.user.displayName.split(" ")
        : [];

      requestBody = {
        data: {
          email: result.user.email,
          password: "GoogleNonePassword",
          nickname: "default",
          name: displayNameArray[0] || "none",
          lastName: displayNameArray[1] || "none",
          refer: "default",
          cpf: "default",
        },
      };

      console.log(requestBody);
    } catch (error) {
      console.log("API Google Error");
    }

    try {
      const response = await fetch(
        "https://us-central1-clash-fy.cloudfunctions.net/jwtUserDetails",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      if (!response.ok) {
        setError(true);
        throw new Error("User don't exist yet");
      }
    } catch (error) {
      const response = await fetch(
        "https://us-central1-clash-fy.cloudfunctions.net/signup",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );
      if (!response.ok) {
        setError(true);
        throw new Error("Failed to register user.");
      } else {
        navigate("/home");
      }
    }
  };

  return (
    <LoginStyleWrapper>
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="auth_form_content">
              <div className="auth_form">
                <h2>logIn</h2>
                <h3>
                  Insira seu endereço de e-mail e senha para ter acesso a sua
                  conta
                </h3>
                <form>
                  <div className="row input_field_row">
                    <div className="form-group col-md-12">
                      <label htmlFor="email">Email</label>
                      <input
                        type="email"
                        id="email"
                        placeholder="Digite seu Email"
                        className="form-control"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="form-group col-md-12">
                      <label htmlFor="password">Senha</label>
                      <input
                        type="password"
                        id="password"
                        placeholder="Digite sua senha"
                        className="form-control"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="auth_form_bttom">
                    <div
                      className={`pass_remember_check ${
                        isChecked ? "active" : ""
                      }`}
                    >
                      <label>
                        Lembrar
                        <input
                          type="checkbox"
                          onChange={() => setChecked(!isChecked)}
                        />
                      </label>
                      <span
                        className="checkmark"
                        onClick={() => setChecked(!isChecked)}
                      >
                        {" "}
                        <FiCheck />
                      </span>
                    </div>
                    <a href="/forget-password" className="forget_pass_btn">
                      Esqueci minha senha
                    </a>
                  </div>
                  <Button
                    style={{ marginBottom: "10px" }}
                    variant="blue"
                    type="submit"
                    onClick={handleLogin}
                  >
                    {" "}
                    Entrar{" "}
                  </Button>
                  <Button onClick={handleGoogleLogin}>Google</Button>
                </form>
                <h4>
                  Não possui uma conta ?{" "}
                  <a href="/register">Cadastre-se agora !</a>
                </h4>
              </div>
              <div className="autho_from_shadow"></div>
            </div>
          </div>
        </div>
      </div>
    </LoginStyleWrapper>
  );
};

export default Login;
