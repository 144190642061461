import React, { useState, useEffect } from "react";
import Button from "components/button";
import { FiCheck, FiX, FiEdit } from "react-icons/fi";
import ProfileFormStyleWrapper from "./ProfileForm.style";
import Perfil from "./../../../assets/images/team/perfil.jpg";
import { getAuth } from "firebase/auth";
import toast from "react-hot-toast";
import { getStorage, ref, uploadBytes } from "firebase/storage";

const ProfileForm = () => {
  const [user, setUser] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [state, setState] = useState("");
  const [address, setAddress] = useState("");
  const [complement, setComplement] = useState("");
  const [city, setCity] = useState("");
  const [file, setFile] = useState("");
  const [photo, setPhoto] = useState("");
  const [status, setStatus] = useState(true);
  const [cpf, setCpf] = useState("");

  useEffect(() => {
    const callApi = async () => {
      try {
        const auth = getAuth();
        const user = auth.currentUser;
        if (!user) {
          throw new Error("Usuário não autenticado");
        }

        const token = await user.getIdToken();
        const response = await fetch(
          "https://us-central1-clash-fy.cloudfunctions.net/jwtUserDetails",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Erro ao chamar a API");
        }

        const data = await response.json();
        setUser(data);
        const name = data.name;
        const lastName = data.lastName;
        const state = data.state;
        const address = data.address;
        const complement = data.complement;
        const city = data.city;
        const cpf = data.cpf;
        const photo = data.photoURL;

        console.log(data);

        setName(name);
        setLastName(lastName);
        setCpf(cpf);
        setState(state);
        setAddress(address);
        setComplement(complement);
        setCity(city);
        if (!photo) {
          setPhoto(Perfil);
        } else {
          setPhoto(photo);
        }
      } catch (error) {
        console.error("Erro ao chamar a API:", error);
      }

      setLoading(false);
    };

    callApi();
  }, []);

  const [isEditing, setIsEditing] = useState(false);
  const [verify, setVerify] = useState(true);
  const [loading, setLoading] = useState(true);

  const handleEditClick = async () => {
    if (isEditing) {
      const callUserData = async () => {
        setLoading(true);
        try {
          const auth = getAuth();
          const user = auth.currentUser;
          const token = await user.getIdToken();
          if (!user) {
            throw new Error("Usuário não autenticado");
          }

          // Dados de exemplo para atualização do perfil
          const profileData = {
            name: name,
            lastName: lastName,
            state: state,
            city: city,
            address: address,
            complement: complement,
            cpf: cpf,
            // outros campos que você queira atualizar
          };

          const response = await fetch(
            "https://us-central1-clash-fy.cloudfunctions.net/setUserProfile",
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify(profileData), // Envia os dados como JSON
            }
          );

          if (!response.ok) {
            throw new Error("Erro ao chamar a API");
          } else {
            toast.success("Dados do Perfil atualizados com sucesso", {
              duration: 3000, // duração em milissegundos
              position: "top-center", // posição do toast
            });
          }

          const result = await response.json(); // Caso a API retorne uma resposta
          console.log("Perfil atualizado com sucesso:", result);
        } catch (error) {
          console.error("Erro ao chamar a API:", error);
        }
      };

      callUserData();
      uploadUserPhoto(file);
      setIsEditing(false);
      setLoading(false);
    } else {
      setIsEditing(true);
    }
  };

  const uploadUserPhoto = async () => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      const token = await user.getIdToken();

      const storage = getStorage();
      const storageRef = ref(storage, `user_photos/${user.uid}/${file.name}`);

      // Upload the file
      await uploadBytes(storageRef, file);

      // Call the Cloud Function to set the user's photo URL
      const response = await fetch(
        "https://us-central1-clash-fy.cloudfunctions.net/setUserPhoto",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: user.uid,
            photoFileName: file.name,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log("Photo URL updated:", data.photoURL);

      toast.success("Foto de Perfil atualizados com sucesso", {
        duration: 3000,
        position: "top-center",
      });
    } catch (error) {
      console.error("Error uploading photo:", error);
      toast.error(`Erro em atualizar foto de perfil: ${error.message}`, {
        duration: 3000,
        position: "top-center",
      });
    }
  };

  if (loading) {
    return (
      <div className="kyc_form">
        <div className="kyc_user_form">
          <div style={{ justifyContent: "center", textAlign: "center" }}>
            <div
              style={{
                marginBottom: "1000px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <h1 style={{ fontSize: "50px" }}>Carregando...</h1>
              <div
                style={{
                  border: "8px solid #f3f3f3",
                  borderTop: "8px solid #3498db",
                  borderRadius: "50%",
                  width: "100px",
                  height: "100px",
                  animation: "spin 1s linear infinite",
                  transform: "translate(-50%, -50%)",
                }}
              ></div>
            </div>
            <style>
              {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
            </style>
            <div className="loading-animation"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <ProfileFormStyleWrapper>
      <div className="kyc_form">
        <div className="kyc_user_form">
          <form>
            <div style={{}}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  marginBottom: "40px",
                }}
              >
                {loading ? (
                  <>
                    <div
                      style={{
                        border: "8px solid #f3f3f3",
                        borderTop: "8px solid #3498db",
                        borderRadius: "50%",
                        width: "25px",
                        height: "25px",
                        animation: "spin 1s linear infinite",
                      }}
                    ></div>
                    <style>
                      {`
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    `}
                    </style>
                  </>
                ) : (
                  <img
                    style={{
                      width: "20vh",
                      height: "20vh",
                      borderRadius: "100%",
                    }}
                    src={photo}
                    alt="Perfil"
                  />
                )}
                {isEditing && (
                  <div
                    style={{ position: "relative", display: "inline-block" }}
                  >
                    <input
                      type="file"
                      onChange={(event) => setFile(event.target.files[0])}
                      style={{
                        opacity: 1, // Make input invisible
                        width: "100%",
                        height: "100%",
                        cursor: "pointer",
                        border: "2px solid #2e2e3c",
                        marginTop: "10px",
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-6">
                <label>Nome</label>
                <input
                  type="text"
                  id="firstName"
                  placeholder="Ex: José"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  readOnly={!isEditing}
                />
              </div>
              <div className="form-group col-md-6">
                <label>Sobrenome</label>
                <input
                  type="text"
                  id="lastName"
                  placeholder="Ex: Silva"
                  className="form-control"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  readOnly={!isEditing}
                />
              </div>
            </div>
            <div className="input_field_wrapper form-group col-md-12">
              <label>Estado</label>
              <input
                type="text"
                id="state"
                placeholder="Ex: Rio de Janeiro"
                className="form-control"
                value={state}
                onChange={(e) => setState(e.target.value)}
                readOnly={!isEditing}
              />
            </div>
            <div className="row">
              <div className="form-group col-md-6">
                <label>Endereço</label>
                <input
                  type="text"
                  id="address"
                  placeholder="Ex: Rua Brasil 721"
                  className="form-control"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  readOnly={!isEditing}
                />
              </div>
              <div className="form-group col-md-6">
                <label>Complemento</label>
                <input
                  type="text"
                  id="complement"
                  placeholder="Ex: Bloco 4"
                  className="form-control"
                  value={complement}
                  onChange={(e) => setComplement(e.target.value)}
                  readOnly={!isEditing}
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group col-md-6">
                <label>Cidade</label>
                <input
                  type="text"
                  id="city"
                  placeholder="Ex: Rio de Janeiro"
                  className="form-control"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  readOnly={!isEditing}
                />
              </div>
              <div className="form-group col-md-6">
                <label>CPF</label>
                <input
                  type="text"
                  id="postCode"
                  placeholder="Ex: 5432-500"
                  className="form-control"
                  value={cpf}
                  onChange={(e) => setCpf(e.target.value)}
                  readOnly={!isEditing}
                />
              </div>
              <h3>
                STATUS:{" "}
                {status ? (
                  <FiCheck style={{ color: "green" }} />
                ) : (
                  <FiX style={{ color: "red" }} />
                )}
              </h3>
            </div>
          </form>
        </div>

        <Button
          href="#"
          variant="blue"
          onClick={handleEditClick}
          disabled={!verify && !isEditing}
        >
          {isEditing ? "SALVAR" : "EDITAR PERFIL"}
        </Button>
      </div>
    </ProfileFormStyleWrapper>
  );
};

export default ProfileForm;
