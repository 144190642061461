import styled from "styled-components";
import React, { useState, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import Button from "components/button";
import { SectionTitle } from "components/sectionTitle";
import data from "assets/data/team/dataV1";
import TeamMember from "sections/Mentor/TeamMemberCard";
import CardHover from "components/cardHover";
import Audio from "assets/sounds/cash-register.mp3";
import { is } from "date-fns/locale";

function CardGame() {
  const [selectedCard, setSelectedCard] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [votes, setVotes] = useState("");
  const [voteConfirmed, setVoteConfirmed] = useState(false);
  const [isInputFilled, setIsInputFilled] = useState(false);

  const audioRef = useRef(null);

  const Main = styled.main`
    grid-area: main;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: top;
    text-align: center;
  `;

  const TeamMemberStyleWrapper = styled.article`
    position: relative;
    background: rgba(26, 27, 47, 0.85);
    backdrop-filter: blur(5px);
    margin-bottom: 30px;
    padding: 35px 0 30px 0;
    text-align: center;
    transition: 1.4s;

    &.team-item {
      img {
        margin-bottom: 45px;
      }
      .dsc {
        margin-bottom: 15px;
      }
    }
    .team-title {
      margin-bottom: 8px;
      a {
        color: #ffffff;
        transition: 0.4s;
      }
    }

    .team-icon-list {
      li {
        display: inline-block;
        padding: 0 7px;
        a {
          color: rgba(255, 255, 255, 0.5);
          transition: 0.4s;
          svg {
            font-size: 20px;
          }
          &:hover {
            color: #ffffff;
          }
        }
      }
    }

    &:hover,
    &.selected {
      background-image: radial-gradient(
        circle,
        rgba(137, 120, 211, 0.4) 0%,
        #1e1f35 100%
      );
      .card-hover-wrapper {
        opacity: 1;
        visibility: visible;
      }
    }

    @media only screen and (max-width: 991px) {
      &.team-item {
        img {
          margin-bottom: 30px;
        }
      }
    }
    @media only screen and (max-width: 480px) {
      &.team-item {
        margin-bottom: 20px;
      }
    }
  `;

  const CardContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
    width: 100%;
    max-width: 1200px;
    margin: 20px 0;
    @media (max-width: 1300px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 800px) {
      grid-template-columns: repeat(1, 1fr);
    }
  `;

  const CardWrapper = styled.div`
    border-radius: 8px;
    text-align: center;
    opacity: 0.9;
    transition: transform 0.3s, opacity 0.3s;
    cursor: pointer;
    height: 90%;

    &:hover,
    &.selected {
      transform: scale(1.05);
      background-image: radial-gradient(
        circle,
        rgba(137, 120, 211, 0.4) 0%,
        #1e1f35 100%
      );
      .card-hover-wrapper {
        opacity: 1;
        visibility: visible;
      }
    }
  `;

  const VotingSection = styled.div`
    margin-top: 20px;
    text-align: center;
  `;

  const Modal = styled.div`
    display: ${(props) => (props.show ? "flex" : "none")};
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #151625;
    padding: 10px;
    border-radius: 10px;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    color: white;
  `;

  const Overlay = styled.div`
    display: ${(props) => (props.show ? "block" : "none")};
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
  `;

  const CloseButton = styled.button`
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    color: white;
    font-size: 20px;
    cursor: pointer;
  `;

  const InputField = styled.input`
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    border: 1px solid gray;
    font-size: 16px;
    width: 120px;
    text-align: center;
  `;

  const mobileSize = useMediaQuery({ maxWidth: 500 });
  const mobileSmallSize = useMediaQuery({ maxWidth: 400 });

  const handleCardClick = (index) => {
    setSelectedCard(selectedCard === index ? null : index);
  };

  const handleVoteClick = () => {
    setShowModal(true);
    setVoteConfirmed(false);
  };

  const handleConfirmVote = () => {
    setVoteConfirmed(true);
    if (audioRef.current) {
      audioRef.current.play().catch((error) => {
        console.error("Error playing audio:", error);
      });
    }
  
    setTimeout(() => {
      setShowModal(false);
      setSelectedCard(null);
      setVotes("");
      setIsInputFilled(false);
    }, 2000);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setVotes("");
    setIsInputFilled(false);
  };

  const handleInputChange = (e) => {
    setVotes(e.target.value);
    setIsInputFilled(e.target.value !== "");
  };

  const handleVoteIncrement = (increment) => {
    setVotes((prevVotes) => {
      const newVotes = Math.max(0, parseInt(prevVotes || 0) + increment);
      setIsInputFilled(newVotes > 0);
      return newVotes;
    });
  };

  return (
    <div className="App">
      <Main>
        <SectionTitle isCenter={true} title="ESCOLHA SEU INFLUENCER FAVORITO" />
        <CardContainer>
          {data?.map((member, index) => (
            <CardWrapper
              key={index}
              onClick={() => handleCardClick(index)}
              className={selectedCard === index ? "selected" : ""}
              isSelected={selectedCard === index}
            >
              <TeamMemberStyleWrapper className="team-item">
                <img src={member.avatar} alt="Team-thumb" />
                <h4 className="team-title mb-8">
                  <a 
                  // href="/team-details"
                  >{member.name}</a>
                </h4>
                <div className="dsc">{member.designation}</div>
                <ul className="team-icon-list">
                  {member.socialLinks?.map((item, i) => (
                    <li key={i}>
                      <a 
                      // href={item.url}
                      >{item.icon}</a>
                    </li>
                  ))}
                </ul>

                <CardHover />
              </TeamMemberStyleWrapper>
            </CardWrapper>
          ))}
        </CardContainer>
        <VotingSection>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                width: "300px",
                height: "50px",
                marginTop: "-40px",
                backgroundColor: selectedCard === null ? "gray" : "green",
                cursor: selectedCard === null ? "auto" : "pointer",
              }}
              onClick={selectedCard !== null ? handleVoteClick : null}
            >
              Votar
            </Button>
          </div>
        </VotingSection>
      </Main>
      <Overlay show={showModal} onClick={handleCloseModal} />
      <Modal show={showModal}>
        <CloseButton onClick={handleCloseModal}>×</CloseButton>
        {!voteConfirmed ? (
          <div
            style={{
              backgroundColor: "#151625",
              width: "500px",
              height: "400px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              textAlign: "center",
              gap: "5px",
            }}
          >
            <h3>Você está votando em:</h3>
            <h4>{data[selectedCard]?.name}</h4>
            <InputField
              type="number"
              value={votes}
              onChange={handleInputChange}
            />
            <p>Valor total: R$ {votes * 2}</p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Button
                style={{ backgroundColor: "red", width: "80px" }}
                onClick={() => handleVoteIncrement(1)}
              >
                +5
              </Button>
              <Button
                style={{ backgroundColor: "red", width: "80px" }}
                onClick={() => handleVoteIncrement(5)}
              >
                +10
              </Button>
              <Button
                style={{ backgroundColor: "red", width: "80px" }}
                onClick={() => handleVoteIncrement(10)}
              >
                +20
              </Button>
            </div>
            {!isInputFilled && (
              <Button
                disabled={!isInputFilled}
                style={{
                  marginTop: "20px",
                  width: "200px",
                  backgroundColor: "gray",
                }}
              >
                Confirmar Voto
              </Button>
            )}
            {isInputFilled && (
              <Button
                disabled={!isInputFilled}
                onClick={isInputFilled ? handleConfirmVote : null}
                style={{
                  marginTop: "20px",
                  width: "200px",
                  backgroundColor: "green",
                }}
              >
                Confirmar Voto
              </Button>
            )}
          </div>
        ) : (
          <div
            style={{
              backgroundColor: "#151625",
              width: "400px",
              height: "300px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <h2>Parabéns, seu voto foi feito com sucesso!</h2>
          </div>
        )}
      </Modal>

      <audio ref={audioRef} src={Audio} />

    </div>
  );
}

export default CardGame;
