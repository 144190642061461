import React, { useState, useEffect } from "react";
import styled from "styled-components";
import DepositModal from "../depositModal/DepositModal";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Button from "components/button";

// Estilizando o fundo escurecido
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4); /* Fundo levemente escurecido */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

// Estilizando o popup
const Popup = styled.div`
  position: absolute;
  width: 90%;
  max-width: 600px;
  background: #1e1f35;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
`;

// Estilizando a imagem no popup
const Image = styled.img`
  width: 90%;
  height: 30vh;
  object-fit: cover;
  margin-bottom: 20px;
`;

export default function FirstLoginPopUp() {
  const [isFirstLogin, setIsFirstLogin] = useState(false);
  const [showDepositModal, setShowDepositModal] = useState(false);

  useEffect(() => {
    const auth = getAuth();

    onAuthStateChanged(auth, (user) => {
      if (user) {
        const creationTime = user.metadata.creationTime;
        const lastSignInTime = user.metadata.lastSignInTime;

        // Verifica se é o primeiro login
        if (creationTime === lastSignInTime) {
          setIsFirstLogin(true);
        }
      }
    });
  }, []);

  const handleOpenDepositModal = () => {
    setShowDepositModal(true);
    setIsFirstLogin(false);
  };

  const handleClosePopup = () => {
    setShowDepositModal(false);
    setIsFirstLogin(false);
  };

  return (
    <>
      {showDepositModal && (
        <DepositModal
          isOpen={showDepositModal}
          onRequestClose={handleClosePopup}
        />
      )}
      {isFirstLogin && (
        <Overlay onClick={handleClosePopup}>
          <Popup onClick={(e) => e.stopPropagation()}>
            <Image src="/path/to/welcome-image.jpg" alt="Welcome" />
            <h2>Bem-vindo à nossa plataforma!</h2>
            <p>
              Descubra como você pode lucrar usando nossa plataforma e aproveite
              todos os benefícios.
            </p>
            <Button
              style={{
                backgroundColor: "red",
                width: "125px",
                height: "50px",
                marginInline: "auto",
              }}
              variant="red"
              className="connect_btn"
              onClick={handleOpenDepositModal}
            >
              Deposito
            </Button>
          </Popup>
        </Overlay>
      )}
    </>
  );
}
