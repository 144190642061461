import { useEffect, useState } from "react";
import { useModal } from "utils/ModalContext";
import {
  MdNotes,
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import NavWrapper from "./Header.style";
import Button from "components/button";
import MobileMenu from "../MobileMenu/MobileMenu";

import data from "assets/data/menu/menuData";
import logo from "assets/images/ClashFy_White.png";
import connectIcon from "assets/images/icons/connect.png";
import walletIcon1 from "assets/images/icons/pancake.png";
import walletIcon2 from "assets/images/icons/uniswap.png";
import walletIcon3 from "assets/images/icons/market.png";
import walletIcon4 from "assets/images/icons/gate.png";
import { getAuth, signOut } from "firebase/auth";
import { auth } from "firebase-config";
import { Navigate, useNavigate } from "react-router-dom";
import DepositModal from "components/modal/depositModal/DepositModal";
import DepositModal2 from "components/modal/depositModal2/DepositModal2";
import Perfil from "./../../../assets/images/team/perfil.jpg";

const Header = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const { walletModalHandle } = useModal();
  const [isMobileMenu, setMobileMenu] = useState(false);
  const [showDepositModal, setShowDepositModal] = useState(false);
  const [showDepositModal2, setShowDepositModal2] = useState(false);
  const [isLoadingBalance, setIsLoadingBalance] = useState(true);
  const [photo, setPhoto] = useState("");

  const handleDepositModal = () => {
    setShowDepositModal(!showDepositModal);
  };

  const navigate = useNavigate();

  const handleMobileMenu = () => {
    setMobileMenu(!isMobileMenu);
  };

  const handleWalletBtn = (e) => {
    e.preventDefault();
    walletModalHandle();
  };

  const handleLogout = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        await signOut(auth);
        setIsLoggedIn(false);
        navigate("/");
        console.log("Logout realizado com sucesso!");
      } else {
        console.log("Nenhum usuário logado.");
      }
    } catch (error) {
      console.error("Erro ao fazer logout:", error);
    }
  };

  useEffect(() => {
    const auth = getAuth();
    auth.onAuthStateChanged((user) => {
      if (user) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    });
  }, []);

  const [saldo, setSaldo] = useState("");

  useEffect(() => {
    if (saldo !== "") {
      setIsLoadingBalance(false);
    }
  }, [saldo]);

  const callApi = async () => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) {
        throw new Error("Usuário não autenticado");
      }

      const token = await user.getIdToken();
      const response = await fetch(
        "https://us-central1-clash-fy.cloudfunctions.net/jwtUserDetails",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Erro ao chamar a API");
      }

      const data = await response.json();
      if (!data.photoURL) {
        setPhoto(Perfil);
      } else {
        setPhoto(data.photoURL);
      }
      const novoSaldo = `$ ${data.balance.toFixed(2)}`;
      setSaldo(novoSaldo);
    } catch (error) {
      console.error("Erro ao chamar a API:", error);
    }
  };

  useEffect(() => {
    callApi();

    const interval = setInterval(() => {
      callApi();
    }, 30 * 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <NavWrapper className="gamfi_header" id="navbar">
      <div className="container">
        {/* Main Menu Start */}
        <div className="gamfi_menu_sect">
          <div className="gamfi_menu_right_sect gamfi_v1_menu_right_sect">
            <div className="gamfi_menu_list">
              <ul>
                {/* menu */}
                {data?.map((menu, i) => (
                  <li key={i}>
                    {/* <a
                      href={
                        menu.title === "Home"
                          ? isLoggedIn
                            ? "/home"
                            : "/"
                          : menu.url
                      }
                    >
                      {menu.title}{" "}
                      {menu.subMenus?.length > 0 && (
                        <MdOutlineKeyboardArrowDown />
                      )}
                    </a> */}

                    {/* if has subMenu and length is greater than 0 */}
                    {menu.subMenus?.length > 0 && (
                      <ul className="sub_menu_list">
                        {menu.subMenus?.map((subMenu, i) => (
                          <li key={i}>
                            <a href={subMenu.url}>
                              {subMenu.title}{" "}
                              {subMenu?.subMenuChilds?.length > 0 && (
                                <MdOutlineKeyboardArrowRight />
                              )}
                            </a>

                            {/* if subMenu child has menu child */}
                            {subMenu?.subMenuChilds?.length > 0 && (
                              <ul className="sub_menu_child_list">
                                {subMenu?.subMenuChilds?.map((subChild, i) => (
                                  <li key={i}>
                                    <a href={subChild.url}>
                                      {" "}
                                      {subChild.title}{" "}
                                    </a>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            </div>

            <div className="gamfi_menu_btns">
              <div className="gamfi_menu_left_sect">
                <div className="logoheader">
                  <a href="/home">
                    <img src={logo} alt="gamfi nft logo" />
                  </a>
                </div>
              </div>
              {isLoggedIn ? (
                <>
                  {isLoadingBalance ? (
                    <div className="wallet_btn2">
                      <div
                        style={{
                          border: "8px solid #f3f3f3",
                          borderTop: "8px solid #3498db",
                          borderRadius: "50%",
                          width: "25px",
                          height: "25px",
                          animation: "spin 1s linear infinite",
                        }}
                      ></div>
                      <style>
                        {`
                        @keyframes spin {
                          0% { transform: rotate(0deg); }
                          100% { transform: rotate(360deg); }
                        }
                      `}
                      </style>
                    </div>
                  ) : (
                    <>
                      <div className="wallet_btn2">{saldo}</div>
                    </>
                  )}
                  <Button
                    style={{
                      backgroundColor: "red",
                      width: "125px",
                      height: "50px",
                      marginLeft: "-25px",
                      marginRight: "auto",
                    }}
                    variant="red"
                    className="connect_btn"
                    onClick={() => setShowDepositModal(true)}
                  >
                    Deposito
                  </Button>

                  {/* <Button
                    style={{ backgroundColor: "red", width: "15vh" }}
                    sm
                    variant="red"
                    className="connect_btn"
                    onClick={() => setShowDepositModal2(true)}
                  >
                    2
                  </Button> */}

                  {showDepositModal && (
                    <DepositModal
                      isOpen={showDepositModal}
                      onRequestClose={() => setShowDepositModal(false)}
                    />
                  )}

                  {showDepositModal2 && (
                    <DepositModal2
                      isOpen={showDepositModal2}
                      onRequestClose={() => setShowDepositModal2(false)}
                    />
                  )}

                  <div className="wallet_btn">
                    {isLoadingBalance ? (
                      <>
                        <div
                          style={{
                            border: "8px solid #f3f3f3",
                            borderTop: "8px solid #3498db",
                            borderRadius: "50%",
                            width: "25px",
                            height: "25px",
                            animation: "spin 1s linear infinite",
                          }}
                        ></div>
                        <style>
                          {`
                          @keyframes spin {
                            0% { transform: rotate(0deg); }
                            100% { transform: rotate(360deg); }
                          }
                        `}
                        </style>
                      </>
                    ) : (
                      <img
                        style={{
                          width: "8vh",
                          height: "8vh",
                          marginRight: "-10px",
                          borderRadius: "100%",
                        }}
                        src={photo}
                        alt="perfil"
                      />
                    )}
                    <MdOutlineKeyboardArrowDown />
                    <div
                      className="wallet_token_list"
                      style={{ marginTop: "10px" }}
                    >
                      <a href="/profile">
                        <img src={walletIcon1} alt="icon" /> Perfil
                      </a>
                      {/* <a href="/partners">
                        <img src={walletIcon2} alt="icon" /> Parceiros
                      </a> */}
                      {/* <a href="/game">
                        <img src={walletIcon3} alt="icon" /> Yolo Game
                      </a> */}
                      {/* <a href="/gamecard">
                        <img src={walletIcon3} alt="icon" /> Card Game
                      </a> */}
                      <a href="/transactions">
                        <img src={walletIcon2} alt="icon" /> Transações
                      </a>
                      {/* <a href="/vsl" target="_blank">
                        <img src={walletIcon3} alt="icon" /> Vsl
                      </a> */}
                      <a href="#" onClick={handleLogout}>
                        <img src={walletIcon4} alt="icon" /> Deslogar
                      </a>
                    </div>
                    {/* <Button
                      href="# "
                      sm
                      variant="white"
                      className="connect_btn"
                      onClick={(e) => handleWalletBtn(e)}
                    >
                      <img src={connectIcon} alt="icon" />
                      Connect
                    </Button> */}
                  </div>
                </>
              ) : (
                <Button
                  href="/login"
                  sm
                  variant="white"
                  className="connect_btn"
                >
                  Login
                </Button>
              )}
            </div>
          </div>
        </div>
        {/* <!-- Main Menu END --> */}
        {isMobileMenu && <MobileMenu mobileMenuhandle={handleMobileMenu} />}
      </div>
    </NavWrapper>
  );
};
export default Header;
