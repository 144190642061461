import Button from "components/button";
import BannerStyleWrapper from "./Banner.style";

import bannerIcon from "assets/images/icons/icon1.png";

const Banner = () => {
  return (
    <>
      <BannerStyleWrapper>
        <div className="container">
          <div className="banner-content text-center">
            <img src={bannerIcon} className="banner-icon" alt="banner icon" />
            <h1 className="banner-title">
              Conheça a ClashFy a maior plataforma de Skill games &amp; Crypto
            </h1>
            <div className="description">
              Cadastre-se e receba bônus de depósito de até R$ 1.000!
            </div>

            <Button
              href="/login"
              variant="mint"
              md
              isCenter
              className="banner-btn"
            >
              ENTRAR AGORA
            </Button>
          </div>
        </div>
      </BannerStyleWrapper>
    </>
  );
};

export default Banner;
