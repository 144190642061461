import { Fragment, useState } from "react";
import { useModal } from "utils/ModalContext";
import GlobalStyles from "assets/styles/GlobalStyles";
import Layout from "components/layout";
import WalletModal from "components/modal/walletModal/WalletModal";
import MetamaskModal from "components/modal/metamaskModal/MetamaskModal";
import Banner from "sections/Banner/v1";
import Header from "sections/Header/v2";
import About from "sections/About/v1";
import NextProjects from "sections/Projects/v1";
import PreviousProjects from "sections/Projects/v3";
import Statistics from "sections/Statistics/v1";
import Team from "sections/Team/v1";
import Partner from "sections/Partner/v1";
import Footer from "sections/Footer/v1";
import PopupModal from "components/modal/popupModal/PopupModal";
import CookiesModal from "components/modal/cookiesModal/CookiesModal";

export default function HomeOne() {
  const [popup, setPopup] = useState(false);
  const [cookies, setCookies] = useState(true);
  const { walletModalvisibility, metamaskModal } = useModal();
  return (
    <Fragment>
      <Layout>
        <GlobalStyles />
        {walletModalvisibility && <WalletModal />}
        {metamaskModal && <MetamaskModal />}
        <Header />
        {popup && (
          <PopupModal isOpen={popup} onRequestClose={() => setPopup(false)} />
        )}
        {cookies && (
          <CookiesModal isOpen={cookies} onRequestClose={() => setCookies(false)} />
        )}
        <Banner />
        <About />
        <NextProjects />
        <PreviousProjects />
        <Statistics />
        <Team />
        <Partner />
        <Footer />
      </Layout>
    </Fragment>
  );
}
